.root {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  max-width: 90dvw;
  @media (width < 360px) {
    min-width: 80dvw;
  }
  button {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    border: none;
  }
}
.content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.success {
  background: rgb(111, 206, 150);
}
.error {
  background-color: #eb5757;
}
.title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 700;
  line-height: -1px;
  letter-spacing: 0px;
  text-align: left;
}
.body {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 500;
  line-height: -1px;
  letter-spacing: 0px;
  text-align: left;
  max-width: 210px;
  text-wrap: wrap;
}
