.root {
  display: flex;
  position: relative;
}

.content {
  padding-inline: 20px;
  min-height: 100dvh;
  padding-bottom: 150px;
  min-width: 100%;
}
