.root {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-width: 100vw;
  background-color: #1f2227;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 19px 0;
  column-gap: 40px;
  z-index: 2;
}
