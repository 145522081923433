.wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 300;

  .root {
    display: flex;
    align-items: center;
    position: relative;
    animation: commigCard 1s 0s 1;
  }
}

@keyframes commigCard {
  0% {
    right: -600px;
  }
  100% {
    right: 0px;
  }
}
