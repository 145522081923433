@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/Gilroy-Regular.ttf") format("woff2");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 500;
  src: url("../../../public/fonts/Gilroy-Medium.ttf") format("woff2");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/Gilroy-Semibold.ttf") format("woff2");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 700;
  src: url("../../../public/fonts/Gilroy-Bold.ttf") format("woff2");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 800;
  src: url("../../../public/fonts/Gilroy-Extrabold.ttf") format("woff2");
}
